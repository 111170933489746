import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';
import Menu from "./service/Menu";

const routes = [
    
    {
        path: '/dashboard',
        name: 'app',
        component: App,
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import('./components/Dashboard.vue')
            },
            {
                path: '/formlayout',
                meta: {
                    authRequires: true
                },
                name: 'formlayout',
                component: () => import('./components/FormLayoutDemo.vue')
            },
            {
                path: '/input',
                meta: {
                    authRequires: true
                },
                name: 'input',
                component: () => import('./components/InputDemo.vue')
            },
            {
                path: '/floatlabel',
                meta: {
                    authRequires: true
                },
                name: 'floatlabel',
                component: () => import('./components/FloatLabelDemo.vue')
            },
            {
                path: '/invalidstate',
                meta: {
                    authRequires: true
                },
                name: 'invalidstate',
                component: () => import('./components/InvalidStateDemo.vue')
            },
            {
                path: '/button',
                meta: {
                    authRequires: true
                },
                name: 'button',
                component: () => import('./components/ButtonDemo.vue')
            },
            {
                path: '/table',
                meta: {
                    authRequires: true
                },
                name: 'table',
                component: () => import('./components/TableDemo.vue'),
                
            },
            {
                path: '/list',
                meta: {
                    authRequires: true
                },
                name: 'list',
                component: () => import('./components/ListDemo.vue')
            },
            {
                path: '/tree',
                meta: {
                    authRequires: true
                },
                name: 'tree',
                component: () => import('./components/TreeDemo.vue')
            },
            {
                path: '/panel',
                meta: {
                    authRequires: true
                },
                name: 'panel',
                component: () => import('./components/PanelsDemo.vue')
            },
            {
                path: '/overlay',
                meta: {
                    authRequires: true
                },
                name: 'overlay',
                component: () => import('./components/OverlayDemo.vue')
            },
            {
                path: '/media',
                meta: {
                    authRequires: true
                },
                name: 'media',
                component: () => import('./components/MediaDemo.vue')
            },
            {
                path: '/menu',
                component: () => import('./components/MenuDemo.vue'),
                children: [
                    {
                        path: '',
                        component: () => import('./components/menu/PersonalDemo.vue')
                    },
                    {
                        path: '/menu/seat',
                        component: () => import('./components/menu/SeatDemo.vue')
                    },
                    {
                        path: '/menu/payment',
                        component: () => import('./components/menu/PaymentDemo.vue')
                    },
                    {
                        path: '/menu/confirmation',
                        component: () => import('./components/menu/ConfirmationDemo.vue')
                    },
                ],
            },
            {
                path: '/messages',
                meta: {
                    authRequires: true
                },
                name: 'messages',
                component: () => import('./components/MessagesDemo.vue')
            },
            {
                path: '/file',
                meta: {
                    authRequires: true
                },
                name: 'file',
                component: () => import('./components/FileDemo.vue')
            },
            {
                path: '/chart',
                meta: {
                    authRequires: true
                },
                name: 'chart',
                component: () => import('./components/ChartDemo.vue')
            },
            {
                path: '/misc',
                meta: {
                    authRequires: true
                },
                name: 'misc',
                component: () => import('./components/MiscDemo.vue')
            },
            {
                path: '/crud',
                meta: {
                    authRequires: true
                },
                name: 'crud',
                component: () => import('./pages/CrudDemo.vue')
            },
            {
                path: '/listmaster/:id?',
                meta: {
                    authRequires: true
                },
                name: 'listmaster',
                component: () => import('./pages/DataUsagesMaster.vue')
            },
            {
                path: '/newsmaster/:id?',
                meta: {
                    authRequires: true
                },
                name: 'newsmaster',
                component: () => import('./pages/NewsMaster.vue')
            },
            {
                path: '/videomaster/:id?',
                meta: {
                    authRequires: true
                },
                name: 'videomaster',
                component: () => import('./pages/VideoMaster.vue')
            },
            {
                path: '/schememaster/:id?',
                meta: {
                    authRequires: true
                },
                name: 'schememaster',
                component: () => import('./pages/SchemeMaster.vue')
            },
            {
                path: '/governmentmaster/:id?',
                meta: {
                    authRequires: true
                },
                name: 'governmentmaster',
                component: () => import('./pages/GovernmentMaster.vue')
            },
            {
                path: '/userqueriesmaster/',
                meta: {
                    authRequires: true
                },
                name: 'userqueriesmaster',
                component: () => import('./pages/UserQueryMaster.vue')
            },
            {
                path: '/querytypemaster/',
                meta: {
                    authRequires: true
                },
                name: 'querytypemaster',
                component: () => import('./pages/QueryTypeMaster.vue')
            },
            {
                path: '/subquerytypemaster/',
                meta: {
                    authRequires: true
                },
                name: 'subquerytypemaster',
                component: () => import('./pages/SubQueryTypeMaster.vue')
            },
            {
                path: '/subquerytypemaster/:id?',
                meta: {
                    authRequires: true
                },
                name: 'subquerytypemaster',
                component: () => import('./pages/SubQueryTypeMaster.vue')
            },
            {
                path: '/syllabusmaster/:id?',
                meta: {
                    authRequires: true
                },
                name: 'syllabusmaster',
                component: () => import('./pages/SyllabusMaster.vue')
            },
            {
                path: '/subjectmaster/:id?',
                meta: {
                    authRequires: true
                },
                name: 'subjectmaster',
                component: () => import('./pages/SubjectMaster.vue')
            },
            {
                path: '/batchmaster/:id?',
                meta: {
                    authRequires: true
                },
                name: 'batchmaster',
                component: () => import('./pages/BatchMaster.vue')
            },
            {
                path: '/yearmaster/:id?',
                meta: {
                    authRequires: true
                },
                name: 'yearmaster',
                component: () => import('./pages/YearMaster.vue')
            },
            {
                path: '/studentmaster/:id?',
                meta: {
                    authRequires: true
                },
                name: 'studentmaster',
                component: () => import('./pages/StudentMaster.vue')
            },
            {
                path: '/assstudentmaster/:id?',
                meta: {
                    authRequires: true
                },
                name: 'assstudentmaster',
                component: () => import('./pages/AssStudentMaster.vue')
            },
            {
                path: '/assteachermaster/:id?',
                meta: {
                    authRequires: true
                },
                name: 'assteachermaster',
                component: () => import('./pages/AssTeacherMaster.vue')
            },
            {
                path: '/teachermaster/:id?',
                meta: {
                    authRequires: true
                },
                name: 'teachermaster',
                component: () => import('./pages/TeacherMaster.vue')
            },
            {
                path: '/usermaster',
                meta: {
                    authRequires: true
                },
                name: 'usermaster',
                component: () => import('./pages/UserMaster.vue')
            },
            {
                path: '/sendnotification',
                meta: {
                    authRequires: true
                },
                name: 'sendnotification',
                component: () => import('./pages/PushNotification.vue')
            },
            {
                path: '/devicemaster',
                meta: {
                    authRequires: true
                },
                name: 'devicemaster',
                component: () => import('./pages/DeviceMaster.vue')
            },
            {
                path: '/notificationconfig',
                meta: {
                    authRequires: true
                },
                name: 'notificationconfig',
                component: () => import('./pages/NotificationConfig.vue')
            },
            {
                path: '/assprofile',
                meta: {
                    authRequires: true
                },
                name: 'assprofile',
                component: () => import('./pages/AssProfile.vue')
            },
            {
                path: '/assistantteachersubmission',
                meta: {
                    authRequires: true
                },
                name: 'assistantteachersubmission',
                component: () => import('./pages/AssistantTeacherSubmission.vue')
            },
            {
                path: '/adminstudentassigment',
                meta: {
                    authRequires: true
                },
                name: 'adminstudentassigment',
                component: () => import('./pages/AdminStudentAssigment.vue')
            },
            {
                path: '/teachersubmittedassigment',
                meta: {
                    authRequires: true
                },
                name: 'teachersubmittedassigment',
                component: () => import('./pages/TeacherSubmittedAssigment.vue')
            },
            {
                path: '/previouslecture',
                meta: {
                    authRequires: true
                },
                name: 'previouslecture',
                component: () => import('./pages/PreviousLecture.vue')
            },
            {
                path: '/previouslectureteacher',
                meta: {
                    authRequires: true
                },
                name: 'previouslectureteacher',
                component: () => import('./pages/PreviousLectureTeacher.vue')
            },
            {
                path: '/studymaterialadmin',
                meta: {
                    authRequires: true
                },
                name: 'studymaterialadmin',
                component: () => import('./pages/StudyMaterialAdmin.vue')
            },
            {
                path: '/todaylecture',
                meta: {
                    authRequires: true
                },
                name: 'todaylecture',
                component: () => import('./pages/TodayLecture.vue')
            },
            {
                path: '/todaylectureteacher',
                meta: {
                    authRequires: true
                },
                name: 'todaylectureteacher',
                component: () => import('./pages/TodayLectureTeacher.vue')
            },
            {
                path: '/schedulelecture',
                meta: {
                    authRequires: true
                },
                name: 'schedulelecture',
                component: () => import('./pages/ScheduleLecture.vue')
            },
            {
                path: '/studentdevicergistration/:id?',
                meta: {
                    authRequires: true
                },
                name: 'studentdevicergistration',
                component: () => import('./pages/StudentDeviceRgistration.vue')
            },
            {
                path: '/upcominglecture/:id?',
                meta: {
                    authRequires: true
                },
                name: 'upcominglecture',
                component: () => import('./pages/UpcomingLecture.vue')
            },
            {
                path: '/testing_page/:id?',
                meta: {
                    authRequires: true
                },
                name: 'testing_page',
                component: () => import('./pages/Testing_page.vue')
            },
            {
                path: '/askdoubt/:id?',
                meta: {
                    authRequires: true
                },
                name: 'askdoubt',
                component: () => import('./pages/AskDoubt.vue')
            },
            {
                path: '/askdoubtteacher/:id?',
                meta: {
                    authRequires: true
                },
                name: 'askdoubtteacher',
                component: () => import('./pages/AskDoubtTeacher.vue')
            },
            {
                path: '/askdoubtassteacher/:id?',
                meta: {
                    authRequires: true
                },
                name: 'askdoubtassteacher',
                component: () => import('./pages/AskDoubtassTeacher.vue')
            },
            {
                path: '/adminreport/:id?',
                meta: {
                    authRequires: true
                },
                name: 'adminreport',
                component: () => import('./pages/AdminReport.vue')
            },
            {
                path: '/studentregistrationreport/:id?',
                meta: {
                    authRequires: true
                },
                name: 'studentregistrationreport',
                component: () => import('./pages/StudentRegistrationReport.vue')
            },
            {
                path: '/lectureviewreport/:id?',
                meta: {
                    authRequires: true
                },
                name: 'lectureviewreport',
                component: () => import('./pages/LectureViewReport.vue')
            },
            {
                path: '/adminteacherlecreport',
                meta: {
                    authRequires: true
                },
                name: 'adminteacherlecreport',
                component: () => import('./pages/AdminTeacherLeRreport.vue')
            },
            //-----------------------------------------------------------------
            {
                path: '/teacherreport/:id?',
                meta: {
                    authRequires: true
                },
                name: 'teacherreport',
                component: () => import('./pages/TeacherReport.vue')
            },
            {
                path: '/lectureviewreportt/:id?',
                meta: {
                    authRequires: true
                },
                name: 'lectureviewreportt',
                component: () => import('./pages/LectureViewReportT.vue')
            },
            {
                path: '/adminteacherlecreportt',
                meta: {
                    authRequires: true
                },
                name: 'adminteacherlecreportt',
                component: () => import('./pages/AdminTeacherLeRreportT.vue')
            },
            //-----------------------------------------------------------------
            {
                path: '/mocktest',
                meta: {
                    authRequires: true
                },
                name: 'mocktest',
                component: () => import('./pages/MockTest.vue')
            },
            {
                path: '/networkusermaster',
                meta: {
                    authRequires: true
                },
                name: 'networkusermaster',
                component: () => import('./pages/NetworkUserMaster.vue')
            },
            {
                path: '/manageliveclass',
                meta: {
                    authRequires: true
                },
                name: 'manageliveclass',
                component: () => import('./pages/ManageLiveClass.vue')
            },
            {
                path: '/testresultassteacher',
                meta: {
                    authRequires: true
                },
                name: 'testresultassteacher',
                component: () => import('./pages/TestResultAssteacher.vue')
            },
            {
                path: '/studentlibrary',
                meta: {
                    authRequires: true
                },
                name: 'studentlibrary',
                component: () => import('./pages/StudentLibrary.vue')
            },
            {
                path: '/testresultstudent',
                meta: {
                    authRequires: true
                },
                name: 'testresultstudent',
                component: () => import('./pages/TestResultStudent.vue')
            },
            {
                path: '/practicetestadmin',
                meta: {
                    authRequires: true
                },
                name: 'practicetestadmin',
                component: () => import('./pages/PracticeTestAdmin.vue')
            },
            {
                path: '/practiceteststudent',
                meta: {
                    authRequires: true
                },
                name: 'practiceteststudent',
                component: () => import('./pages/PracticeTestStudent.vue')
            },
            {
                path: '/studentrecordedsession',
                meta: {
                    authRequires: true
                },
                name: 'studentrecordedsession',
                component: () => import('./pages/StudentRecordedSession.vue')
            },
            {
                path: '/studentstudymaterial',
                meta: {
                    authRequires: true
                },
                name: 'studentstudymaterial',
                component: () => import('./pages/StudentStudyMaterial.vue')
            },
            {
                path: '/studentpendingpaper',
                meta: {
                    authRequires: true
                },
                name: 'studentpendingpaper',
                component: () => import('./pages/StudentPendingPaper.vue')
            },
            {
                path: '/studentsubmitedpaper',
                meta: {
                    authRequires: true
                },
                name: 'studentsubmitedpaper',
                component: () => import('./pages/StudentSubmitedPaper.vue')
            },
            {
                path: '/admindashboard',
                meta: {
                    authRequires: true
                },
                name: 'admindashboard',
                component: () => import('./pages/AdminDashboard.vue')
            },
            {
                path: '/ateacherhome',
                
                name: 'ateacherhome',
                component: () => import('./pages/AssTeacherHome.vue')
            },
            {
                path: '/teacherhome',
               
                name: 'teacherhome',
                component: () => import('./pages/TeacherHome.vue')
            },
            {
                path: '/librarymaster',
                meta: {
                    authRequires: true
                },
                name: 'librarymaster',
                component: () => import('./pages/LibraryMaster.vue')
            },
            {
                path: '/contentcreation',
                meta: {
                    authRequires: true
                },
                name: 'contentcreation',
                component: () => import('./pages/ContentCreation.vue')
            },
            {
                path: '/noticemaster',
                meta: {
                    authRequires: true
                },
                name: 'noticemaster',
                component: () => import('./pages/NoticeMaster.vue')
            },
            {
                path: '/uploadtest',
                meta: {
                    authRequires: true
                },
                name: 'uploadtest',
                component: () => import('./pages/UploadTest.vue')
            },
            {
                path: '/teachertest',
                meta: {
                    authRequires: true
                },
                name: 'teachertest',
                component: () => import('./pages/TeacherTest.vue')
            },
            {
                path: '/recording',
                meta: {
                    authRequires: true
                },
                name: 'recording',
                component: () => import('./pages/RecordingsAdmin.vue')
            },
            {
                path: '/studentdashboard',
                name: 'recording',
                component: () => import('./pages/StudentDashboard.vue')
            },
            {
                path: '/timeline',
                meta: {
                    authRequires: true
                },
                name: 'timeline',
                component: () => import('./pages/TimelineDemo.vue')
            },
            {
                path: '/empty',
                meta: {
                    authRequires: true
                },
                name: 'empty',
                component: () => import('./components/EmptyPage.vue')
            },
            {
                path: '/documentation',
                meta: {
                    authRequires: true
                },
                name: 'documentation',
                component: () => import('./components/Documentation.vue')
            },
            {
                path: '/blocks',
                meta: {
                    authRequires: true
                },
                name: 'blocks',
                component: () => import('./components/BlocksDemo.vue')
            },
            {
                path: '/icons',
                meta: {
                    authRequires: true
                },
                name: 'icons',
                component: () => import('./components/IconsDemo.vue')
            }
            ,
            {
                path: '/networkconnectivityreport1',
                name: 'networkconnectivityreport1',
                component: () => import('./pages/NetworkConnectivityReport1.vue')
            }
            ,
            {
                path: '/networkconnectivityreport2',
                name: 'networkconnectivityreport2',
                component: () => import('./pages/NetworkConnectivityReport2.vue')
            }
        ]
    },
    {
        path: '/',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/landing',
        name: 'landing',
        component: () => import('./pages/LandingDemo.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});


//var page_name = this.$route.name;
var user_name = localStorage.getItem("user_key");
var Menu_master = null;
var autoValue =null;
Menu_master = new Menu();

//router.beforeEach((to, from, next) => {
   
    // if (to.meta.authRequires) 
    // {  
        
    //     if (user_name == "admin") 
    //     { 
    //     Menu_master.get_menuAdmin().then((Admin_data) => {
    //     autoValue = Admin_data;
    //     if (autoValue.indexOf(to.name.toString()) !== -1) {console.log(user_name);next();} else {console.log(user_name);next('/access');}
    //     });
      
    //     } 
    //     else if (user_name == "teacher") 
    //     { 
    //     Menu_master.get_menuTeacher().then((Teacher_data) => {
    //     autoValue = Teacher_data;
    //     if (autoValue.indexOf(to.name.toString()) !== -1) {console.log(user_name);next();} else {console.log(user_name);next("/access");}
    //      });
      
    //     } 
    //     else if (user_name == "assteacher") 
    //     { 
    //     Menu_master.get_menuassistantTeacher().then((Ass_Teacher_data) => {
    //     autoValue = Ass_Teacher_data;
        
    //     if (autoValue.indexOf(to.name.toString()) !== -1) {console.log(user_name); next();} else {console.log(user_name);next("/access");}
    //     });
      
    //     } 
    //     else if (user_name == "network")
    //     { 
    //     Menu_master.get_menunetwork().then((Network_data) => {
    //     autoValue = Network_data;
    //     if (autoValue.indexOf(to.name.toString()) !== -1) {console.log(user_name);next();} else {console.log(user_name);next("/access");}
    //     });
     
    //     }
    //     else if (user_name == "student")
    //     { 
    //     Menu_master.get_menuStudent().then((Student_data) => {
    //     autoValue = Student_data;
    //     if (autoValue.indexOf(to.name.toString()) !== -1) {console.log(user_name);next();} else {console.log(user_name);next("/access");}
    //     });
     
    //     }


    // }
    // else{

    // next();
    // }

    //next();

//});

export default router;
