 var apibaseurl = "https://7gdt5szac1.execute-api.ap-south-1.amazonaws.com/Prod/api/"  //live old testing
//var apibaseurl="https://xlasxcyxck.execute-api.ap-south-1.amazonaws.com/Prod/api/";
 //var apibaseurl = "https://localhost:44368/api/"   //local
//var ip_base_url = "https://13.234.76.213/wages/mathadi_dev/index.php/"
// import Vue from 'vue'
//var querystring = require('querystring');
import axios from 'axios'
// import store from '../store'

// import { authHeader } from '../helpers/auth-header';

// let requestOptions = authHeader()



var apiurls=[
    'Data/datausagelist',
    'Data/networkconnectivitylist',
    'Data/studentlist',
    'Data/teacherlist',
    'Data/stdentregistrationlist',
    'Data/newsmaster',
    'Data/governmentmaster',
    'Data/feedbackmaster',
    'Data/userquerymaster',
    'Data/schemesmaster',
    'Data/noticelistmaster',
    'Data/subjectlistmaster',
    'Data/teacherschedulelist',
    'Data/teacherscheduledelist',
    'Data/QueryTypeList',
    'Data/getQuerySubTypesList',
    'Data/adminuserlistmaster',
    'Data/applicationrolelistmaster',
    'Data/deptlistmaster',
    'Data/castlistmaster',
    'Data/yearlistmaster',
    'Data/districtlistmaster',
    'Data/studentattendancelistmaster',
    'Data/studentdocumentlistmaster',
    'Data/studentaadharlistmaster',
    'Data/studentradicalidlistmaster',
    'Data/subjecttopiclistmaster',
    'Data/querycommentlistmaster',
    'Data/studentsubmittedanswerslistmaster',
    'Data/mocktestlistmaster',
    'Data/mocktestpapersmaster',
    'Data/mocktestresultmaster',
    'Data/videomaster',
    
    
]
var header={
    headers: {
      "Content-Type": "application/json"
}};
export default {
    list(data,id) {
        var link=apiurls[id-1];
        return axios.post(apibaseurl + link, JSON.stringify(data),  header)
    },
    dashboarddatausagereport(data)
    {
        return axios.post(apibaseurl + "Data/datausagereport", JSON.stringify(data),  header)
    },
    todayslecturereport(data)
    {
        return axios.post(apibaseurl + "Data/todayslecturesadmindashboard", JSON.stringify(data),  header)
    },
    teacherlecturereportadmin()
    {
        return axios.post(apibaseurl + "Data/teacherlecturereportadmin", '',  header)
    },
    dashboardcount()
    {
        return axios.post(apibaseurl + "Data/admindashboardcount", '',  header)
    },
    networkconnectivityreport(data)
    {
        return axios.post(apibaseurl + "Data/networkconnectivityreport", JSON.stringify(data),  header)
    },

    login(data)
    {
        return axios.post(apibaseurl + "Data/login", JSON.stringify(data),  header)
    },

    getUserQueriesComments(data)
    {
        return axios.post(apibaseurl + "Data/getUserQueriesComments", JSON.stringify(data),  header)
    },
    saveUserComment(data)
    {
        return axios.post(apibaseurl + "Data/saveUserComment", JSON.stringify(data),  header)
    },
    teacherlistdropdown()
    {
        return axios.post(apibaseurl + "Data/teacherlistdropdown", '',  header)
    },
    assignUserQuery(data)
    {
        return axios.post(apibaseurl + "Data/assignUserQuery", JSON.stringify(data),  header)
    },

    //news master
    newslist(data)
    {
        return axios.post(apibaseurl + "Data/newsmaster", JSON.stringify(data),  header)
    },
    editnewsmaster(data)
    {
        return axios.post(apibaseurl + "Admin/newsedit", JSON.stringify(data),  header)
    },
    addnewsmaster(data)
    {
        return axios.post(apibaseurl + "Admin/newsadd", JSON.stringify(data),  header)
    },
    deletenewsmaster(data)
    {
        return axios.post(apibaseurl + "Admin/newsdelete", JSON.stringify(data),  header)
    },

    //video master
    videolist(data)
    {
        return axios.post(apibaseurl + "Data/videomaster", JSON.stringify(data),  header)
    },
    editvideomaster(data)
    {
        return axios.post(apibaseurl + "Admin/videoedit", JSON.stringify(data),  header)
    },
    addvideomaster(data)
    {
        return axios.post(apibaseurl + "Admin/videoadd", JSON.stringify(data),  header)
    },
    deletevideomaster(data)
    {
        return axios.post(apibaseurl + "Admin/videodelete", JSON.stringify(data),  header)
    },


    //schemes master
    schemelist(data)
    {
        return axios.post(apibaseurl + "Data/schemesmaster", JSON.stringify(data),  header)
    },
    editschememaster(data)
    {
        return axios.post(apibaseurl + "Admin/schemeedit", JSON.stringify(data),  header)
    },
    addschememaster(data)
    {
        return axios.post(apibaseurl + "Admin/schemeadd", JSON.stringify(data),  header)
    },
    deleteschememaster(data)
    {
        return axios.post(apibaseurl + "Admin/schemedelete", JSON.stringify(data),  header)
    },



    //Government master
    governmentlist(data)
    {
        return axios.post(apibaseurl + "Data/governmentmaster", JSON.stringify(data),  header)
    },
    editgovernmentmaster(data)
    {
        return axios.post(apibaseurl + "Admin/govedit", JSON.stringify(data),  header)
    },
    addgovernmentmaster(data)
    {
        return axios.post(apibaseurl + "Admin/govadd", JSON.stringify(data),  header)
    },
    deletegovernmentmaster(data)
    {
        return axios.post(apibaseurl + "Admin/govdelete", JSON.stringify(data),  header)
    },

    //query master
    querylist(data)
    {
        return axios.post(apibaseurl + "Data/QueryTypeList", JSON.stringify(data),  header)
    },
    editquerymaster(data)
    {
        return axios.post(apibaseurl + "Admin/querytypeedit", JSON.stringify(data),  header)
    },
    addquerymaster(data)
    {
        return axios.post(apibaseurl + "Admin/querytypeadd", JSON.stringify(data),  header)
    },
    deletequerymaster(data)
    {
        return axios.post(apibaseurl + "Admin/querytypedelete", JSON.stringify(data),  header)
    },

    //subquery master
    subquerylist(data)
    {
        return axios.post(apibaseurl + "Data/getQuerySubTypesList", JSON.stringify(data),  header)
    },
    editsubquerymaster(data)
    {
        return axios.post(apibaseurl + "Admin/subquerytypeedit", JSON.stringify(data),  header)
    },
    addsubquerymaster(data)
    {
        return axios.post(apibaseurl + "Admin/subquerytypeadd", JSON.stringify(data),  header)
    },
    deletesubquerymaster(data)
    {
        return axios.post(apibaseurl + "Admin/subquerytypedelete", JSON.stringify(data),  header)
    },

    //syllabus master
    syllabuslist(data)
    {
        return axios.post(apibaseurl + "Data/syllabusmaster", JSON.stringify(data),  header)
    },
    editsyllabusmaster(data)
    {
        return axios.post(apibaseurl + "Admin/syllabusedit", JSON.stringify(data),  header)
    },
    addsyllabusmaster(data)
    {
        return axios.post(apibaseurl + "Admin/syllabusadd", JSON.stringify(data),  header)
    },
    deletesyllabusmaster(data)
    {
        return axios.post(apibaseurl + "Admin/syllabusdelete", JSON.stringify(data),  header)
    },

    //subject master
    subjectlist(data)
    {
        return axios.post(apibaseurl + "Data/subjectlistmaster", JSON.stringify(data),  header)
    },
    editsubjectmaster(data)
    {
        return axios.post(apibaseurl + "Admin/subjectedit", JSON.stringify(data),  header)
    },
    addsubjectmaster(data)
    {
        return axios.post(apibaseurl + "Admin/subjectadd", JSON.stringify(data),  header)
    },
    deletesubjectmaster(data)
    {
        return axios.post(apibaseurl + "", JSON.stringify(data),  header)
    },

    //batch master
    batchlist(data)
    {
        return axios.post(apibaseurl + "Data/batchlistmaster", JSON.stringify(data),  header)
    },
    editbatchmaster(data)
    {
        return axios.post(apibaseurl + "Admin/batchedit", JSON.stringify(data),  header)
    },
    addbatchmaster(data)
    {
        return axios.post(apibaseurl + "Admin/batchadd", JSON.stringify(data),  header)
    },
    deletebatchmaster(data)
    {
        return axios.post(apibaseurl + "", JSON.stringify(data),  header)
    },

    //year master
    yearlist(data)
    {
        return axios.post(apibaseurl + "Data/yearlistmaster", JSON.stringify(data),  header)
    },
    edityearmaster(data)
    {
        return axios.post(apibaseurl + "Admin/yearedit", JSON.stringify(data),  header)
    },
    addyearmaster(data)
    {
        return axios.post(apibaseurl + "Admin/yearadd", JSON.stringify(data),  header)
    },
    deleteyearmaster(data)
    {
        return axios.post(apibaseurl + "", JSON.stringify(data),  header)
    },

    //student master
    studentlist(data)
    {
        return axios.post(apibaseurl + "data/studentlist", JSON.stringify(data),  header)
    },
    editstudentmaster(data)
    {
        return axios.post(apibaseurl + "Data/studentedit", JSON.stringify(data),  header)
    },
    addstudentmaster(data)
    {
        return axios.post(apibaseurl + "Data/studentadd", JSON.stringify(data),  header)
    },
    deletestudentmaster(data)
    {
        return axios.post(apibaseurl + "Data/studentdelete", JSON.stringify(data),  header)
    },

    //get district  Data/districtlistmaster
    getdistrictmaster(data)
    {
        return axios.post(apibaseurl + "Data/districtlistmaster", JSON.stringify(data),  header)
    },
    //get cat  
    getcatmaster(data)
    {
        return axios.post(apibaseurl + "data/castlistmaster", JSON.stringify(data),  header)
    },
    //get std doc list
    getdocmaster(data)
    {
        return axios.post(apibaseurl + "Admin/getstudentdocuments", JSON.stringify(data),  header)
    },

    //assstudent master
    assstudentlist(data)
    {
        return axios.post(apibaseurl + "AssTeacher/studentmaster", JSON.stringify(data),  header)
    },
    //resetpassword  //Admin/resetpassword
    passwordreset(data)
    {
        return axios.post(apibaseurl + "Admin/resetpassword", JSON.stringify(data),  header)
    },

    //uploadstudentdocuments
    uploadstudentdocuments(data)
    {
        return axios.post(apibaseurl + "Admin/uploadstudentdocuments", JSON.stringify(data),  header)
    },


    //assteacher master
    assteacherlist(data)
    {
        return axios.post(apibaseurl + "data/assteacherlist", JSON.stringify(data),  header)
    },
    editassteachermaster(data)
    {
        return axios.post(apibaseurl + "Admin/teacheredit", JSON.stringify(data),  header)
    },
    addassteachermaster(data)
    {
        return axios.post(apibaseurl + "Admin/teacheradd", JSON.stringify(data),  header)
    },
    deleteassteachermaster(data)
    {
        return axios.post(apibaseurl + "Admin/teacherdelete", JSON.stringify(data),  header)
    },
    assprofiledetails(data)
    {
        return axios.post(apibaseurl + "AssTeacher/profiledetails", JSON.stringify(data),  header)
    },

    //teacher master
    teacherlist(data)
    {
        return axios.post(apibaseurl + "data/teacherlist", JSON.stringify(data),  header)
    },
    editteachermaster(data)
    {
        return axios.post(apibaseurl + "Admin/teacheredit", JSON.stringify(data),  header)
    },
    addteachermaster(data)
    {
        return axios.post(apibaseurl + "Admin/teacheradd", JSON.stringify(data),  header)
    },
    deleteteachermaster(data)
    {
        return axios.post(apibaseurl + "Admin/teacherdelete", JSON.stringify(data),  header)
    },


    //Previous lecture
    previouslecture(data)
    {
        return axios.post(apibaseurl + "AssTeacher/previouslecture", JSON.stringify(data),  header)
    },
    lectureattendedstudentlist(data)
    {
        return axios.post(apibaseurl + "AssTeacher/lectureattendedstudentlist", JSON.stringify(data),  header)
    },
    uploadtchdetails(data)
    {
        return axios.post(apibaseurl + "AssTeacher/uploadtchdetails", JSON.stringify(data),  header)
    },

    uploadFile(data)
    {
        //return axios.post('https://localhost:44336/api/PreSignedUrl/', data,  header)
        return axios.post(apibaseurl + "Fileupload/geturl", data,  header)
       
    },



    //user master
    userlist(data)
    {
        return axios.post(apibaseurl + "data/adminuserlistmaster", JSON.stringify(data),  header)
    },
    editusermaster(data)
    {
        return axios.post(apibaseurl + "Admin/adminedit", JSON.stringify(data),  header)
    },
    addusermaster(data)
    {
        return axios.post(apibaseurl + "Admin/adminadd", JSON.stringify(data),  header)
    },
    deleteusermaster(data)
    {
        return axios.post(apibaseurl + "Admin/admindelete", JSON.stringify(data),  header)
    },


    //send notification
    pushnotificationlistmaster(data)
    {
        return axios.post(apibaseurl + "data/pushnotificationlistmaster", JSON.stringify(data),  header)
    },
    sendpushnotification(data)
    {
        return axios.post(apibaseurl + "Data/sendpushnotification", JSON.stringify(data),  header)
    },


    // notification config
    pushnotificationconfigurationmaster(data)
    {
        return axios.post(apibaseurl + "data/pushnotificationconfigurationmaster", JSON.stringify(data),  header)
    },
    editpushnotificationconfigurationmaster(data)
    {
        return axios.post(apibaseurl + "Admin/pncedit", JSON.stringify(data),  header)
    },
    addpushnotificationconfigurationmaster(data)
    {
        return axios.post(apibaseurl + "Admin/pncadd", JSON.stringify(data),  header)
    },
    deletepushnotificationconfigurationmaster(data)
    {
        return axios.post(apibaseurl + "Admin/pncdelete", JSON.stringify(data),  header)
    },


    //ass student submission
    studentsubmissiondropdown(data)
    {
        return axios.post(apibaseurl + "AssTeacher/studentsubmissiondropdown", JSON.stringify(data),  header)
    },

    studentsubmissionassignments(data)
    {
        return axios.post(apibaseurl + "AssTeacher/studentsubmissionassignments", JSON.stringify(data),  header)
    }, 
    updateteacherremark(data)
    {
        return axios.post(apibaseurl + "AssTeacher/updateteacherremark", JSON.stringify(data),  header)
    },  


    //admin student assigment
    adminstudentsubmissionassignments(data)
    {
        return axios.post(apibaseurl + "AssTeacher/studentsubmissionassignments", JSON.stringify(data),  header)
    },


    //teacher assigment
    teacherstudentsubmissionassignments(data)
    {
        return axios.post(apibaseurl + "AssTeacher/studentsubmissionassignments", JSON.stringify(data),  header)
    },


    //studymaterial admin
    studymaterialmaster(data)
    {
        return axios.post(apibaseurl + "Admin/studymaterialmaster", JSON.stringify(data),  header)
    },
    subjectwisetopiclist(data)
    {
        return axios.post(apibaseurl + "Admin/subjectwisetopiclist", JSON.stringify(data),  header)
    },


    //recordings

    recordingmaster(data)
    {
        return axios.post(apibaseurl + "Data/recordingmaster", JSON.stringify(data),  header)
    },

    getcourceteacherlist(data)
    {
        return axios.post(apibaseurl + "Admin/getcourceteacherlist", JSON.stringify(data),  header)
    },

    recordingadd(data)
    { 
        return axios.post(apibaseurl + "Admin/recordingadd", JSON.stringify(data),  header)
    },

    recordingedit(data)
    {
        return axios.post(apibaseurl + "Admin/recordingedit", JSON.stringify(data),  header)
    },


    //librarymaster
    librarymaster(data)
    {
        return axios.post(apibaseurl + "Data/librarymaster", JSON.stringify(data),  header)
    },

    //today lecture
    todaylectureadminmaster(data)
    {
        return axios.post(apibaseurl + "Data/todaylectureadminmaster", JSON.stringify(data),  header)
    },
    schedulelecturelist(data)
    {
        return axios.post(apibaseurl + "Admin/schedulelecturelist", JSON.stringify(data),  header)
    },
    schedulelectureview(data)
    {
        return axios.post(apibaseurl + "Admin/schedulelectureview", JSON.stringify(data),  header)
    },
    teachersubjects(data)
    {
        return axios.post(apibaseurl + "Admin/teachersubjects", JSON.stringify(data),  header)
    },
    getsubjecttopic(data)
    {
        return axios.post(apibaseurl + "Admin/getsubjecttopic", JSON.stringify(data),  header)
    },
    schedulelectureedit(data)
    {//
        return axios.post(apibaseurl + "Admin/schedulelectureedit", JSON.stringify(data),  header)
    },
    schedulelectureadd(data)
    {//
        return axios.post(apibaseurl + "Admin/schedulelectureadd", JSON.stringify(data),  header)
    },
    schedulelectureliveadd(data)
    {//Admin/schedulelectureliveadd
        return axios.post(apibaseurl + "Admin/schedulelectureliveadd", JSON.stringify(data),  header)
    },
    schedulelectureliveedit(data)
    {//Admin/schedulelectureliveedit
        return axios.post(apibaseurl + "Admin/schedulelectureliveedit", JSON.stringify(data),  header)
    },
      
    

    //student Device registration   
    stdentregistrationlist(data)
    {
        return axios.post(apibaseurl + "Data/stdentregistrationlist", JSON.stringify(data),  header)
    },

    //content creation  
    contentcreationlist(data)
    {
        return axios.post(apibaseurl + "AssTeacher/contentcreationlist", JSON.stringify(data),  header)
    },

    //upcomming lecture admin  
    upcominglectures(data)
    {
        return axios.post(apibaseurl + "Admin/upcominglectures", JSON.stringify(data),  header)
    },

    //ask doubt admin  
    askdoubtreport(data)
    {
        return axios.post(apibaseurl + "Admin/askdoubtreport", JSON.stringify(data),  header)
    },
    viewdoubt(data)
    {
        return axios.post(apibaseurl + "Admin/viewdoubt", JSON.stringify(data),  header)
    },

    //ask doubt teacher  
    answedoubt(data)
    {
        return axios.post(apibaseurl + "Admin/answedoubt", JSON.stringify(data),  header)
    },

    //admin report
    studentlis(data)
    {
        return axios.post(apibaseurl + "Data/studentlist", JSON.stringify(data),  header)
    },
    adminlectureviewreport(data)
    {
        return axios.post(apibaseurl + "Admin/adminlectureviewreport", JSON.stringify(data),  header)
    },
    adminteacherlecreport(data)
    {
        return axios.post(apibaseurl + "Admin/adminteacherlecreport", JSON.stringify(data),  header)
    },

    //mocktest admin
    mocktestlistmaster(data)
    {
        return axios.post(apibaseurl + "Data/mocktestlistmaster", JSON.stringify(data),  header)
    },
    mocktestadd(data)
    {//Admin/mocktestadd
        return axios.post(apibaseurl + "Admin/mocktestadd", JSON.stringify(data),  header)
    },
    mocktestedit(data)
    {//Admin/mocktestedit
        return axios.post(apibaseurl + "Admin/mocktestedit", JSON.stringify(data),  header)
    },
    mockteststudentlist(data)
    {
        return axios.post(apibaseurl + "Admin/mockteststudentlist", JSON.stringify(data),  header)
    },


    //test result
    TestDateList(data)
    {
        return axios.post(apibaseurl + "Data/TestDateList", JSON.stringify(data),  header)
    },
    TopTenTestResultListAllSubject(data)
    {
        return axios.post(apibaseurl + "Data/TopTenTestResultListAllSubject", JSON.stringify(data),  header)
    },
    subjecttestdetails(data)
    {
        return axios.post(apibaseurl + "Data/subjecttestdetails", JSON.stringify(data),  header)
    },


    //manage live class
    manageliveclasslist(data)
    {
        return axios.post(apibaseurl + "AssTeacher/manageliveclasslist", JSON.stringify(data),  header)
    },
    cancellecture(data)
    {
        return axios.post(apibaseurl + "AssTeacher/cancellecture", JSON.stringify(data),  header)
    },
  
    //dropdown user query master
    helpdesknetworkusersdropdown()
    {
        return axios.post(apibaseurl + "Data/helpdesknetworkusersdropdown", '',  header)
    },

    //today lecture teacher
    todayslectures(data)
    {
        return axios.post(apibaseurl + "Teacher/todayslectures", JSON.stringify(data),  header)
    },

    //network user master
    helpdesknetworkusersmaster(data)
    {
        return axios.post(apibaseurl + "Data/helpdesknetworkusersmaster", JSON.stringify(data),  header)
    },
    helpdesknetworkuseradd(data)
    {//
        return axios.post(apibaseurl + "Admin/helpdesknetworkuseradd", JSON.stringify(data),  header)
    },
    helpdesknetworkuseredit(data)
    {//
        return axios.post(apibaseurl + "Admin/helpdesknetworkuseredit", JSON.stringify(data),  header)
    },
    helpdesknetworkuserdelete(data)
    {//Admin/helpdesknetworkuserdelete
        return axios.post(apibaseurl + "Admin/helpdesknetworkuserdelete", JSON.stringify(data),  header)
    },

    practicepaperslist(data)
    {//
        return axios.post(apibaseurl + "data/practicepaperslist", JSON.stringify(data),  header)
    },  
    PracticePaperList(data)
    {//student
        return axios.post(apibaseurl + "data/PracticePaperList", JSON.stringify(data),  header)
    },   

    dashboarddata(data)
    {//
        return axios.post(apibaseurl + "Admin/dashboarddata", JSON.stringify(data),  header)
    }, 
    dashboarddataleaderboards(data)
    {//
        return axios.post(apibaseurl + "Admin/dashboarddataleaderboards", JSON.stringify(data),  header)
    }, 
    todaysassignmentupload(data)
    {//
        return axios.post(apibaseurl + "Admin/todaysassignmentupload", JSON.stringify(data),  header)
    },  
    dashboarddataA(data)
    {//
        return axios.post(apibaseurl + "AssTeacher/dashboarddata", JSON.stringify(data),  header)
    },    


    //JEE / NEET upload test:
    testpapersmaster(data)
    {//
        return axios.post(apibaseurl + "Data/testpapersmaster", JSON.stringify(data),  header)
    },
    testadd(data)
    {//Data/testadd
        return axios.post(apibaseurl + "Admin/testadd", JSON.stringify(data),  header)
    },
    testedit(data)
    {//Data/testedit
        return axios.post(apibaseurl + "Admin/testedit", JSON.stringify(data),  header)
    },
    testdelete(data)
    {//Data/testdelete
        return axios.post(apibaseurl + "Admin/testdelete", JSON.stringify(data),  header)
    },

    //notice
    noticelistmaster(data)
    {//Data/testdelete
        return axios.post(apibaseurl + "Data/noticelistmaster", JSON.stringify(data),  header)
    },
    noticeadd(data)
    {//Admin/noticeadd
        return axios.post(apibaseurl + "Admin/noticeadd", JSON.stringify(data),  header)
    },
    noticeedit(data)
    {//Admin/noticeedit
        return axios.post(apibaseurl + "Admin/noticeedit", JSON.stringify(data),  header)
    },

    connectivityreport1(data)
    {//Admin/noticeedit
        return axios.post(apibaseurl + "Admin/connectivityreport1", JSON.stringify(data),  header)
    },
    connectivityreport2(data)
    {//Admin/connectivityreport2
        return axios.post(apibaseurl + "Admin/connectivityreport2", JSON.stringify(data),  header)
    },


    studentsubjectlist(data)
    {//Data/subjectlist
        return axios.post(apibaseurl + "Data/subjectlist", JSON.stringify(data),  header)
    },
    studentlibrarydata(data)
    {//Data/subjectlist
        return axios.post(apibaseurl + "Data/library", JSON.stringify(data),  header)
    },


    
    devicelist(data)
    {
        return axios.post(apibaseurl+"data/devicelist", JSON.stringify(data), header)
    },
    deviceinfo(data)
    {
        return axios.post(apibaseurl+"data/deviceinfo", JSON.stringify(data), header)
    },
    devicereboot(data)
    {
        return axios.post(apibaseurl+"data/devicereboot", JSON.stringify(data), header)
    },


     
    
    



}