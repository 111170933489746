<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="set_menu" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
        <VueSimpleAlert />
      </div>
      <AppFooter />
    </div>

    <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" />
    <transition name="layout-mask">
      <div
        class="layout-mask p-component-overlay"
        v-if="mobileMenuActive"
      ></div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppConfig from "./AppConfig.vue";
import AppFooter from "./AppFooter.vue";
import Menu from "./service/Menu";

export default {
  emits: ["change-theme"],
  data() {
    return {
      Menu_master: null,
      autoValue: null,
      set_menu: [
        {
          label: "Home",
          items: [
            {
              label: "Dashboard",
              icon: "pi pi-fw pi-home",
              to: "/dashboard",
            },
            // {label: 'Crud', icon: 'pi pi-fw pi-user-edit', to: '/crud'}
          ],
        },
      ],
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      // menuAdmin : [{
      //         label: 'Home',
      //         items: [{
      //             label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard',

      //         },
      //         // {label: 'Crud', icon: 'pi pi-fw pi-user-edit', to: '/crud'}
      //         ],
      //     },],
      menuassistantTeacher: [
        {
          label: "Home",
          items: [
            { label: "Home", icon: "pi pi-fw pi-home", to: "/ateacherhome" },
            // {label: 'Crud', icon: 'pi pi-fw pi-user-edit', to: '/crud'}
          ],
        },
        {
          label: "Menu",
          icon: "pi pi-fw pi-sitemap",
          items: [
            { label: "Profile", icon: "pi pi-fw pi-user", to: "/assprofile" },
            {
              label: "Manage Live Classes",
              icon: "pi pi-fw pi-video",
              to: "/manageliveclass",
            },
            {
              label: "Previous Lecture",
              icon: "pi pi-fw pi-chevron-circle-right",
              to: "/previouslecture",
            },
            {
              label: "Ask A Doubt",
              icon: "pi pi-fw pi-question-circle",
              to: "/askdoubtassteacher",
            },

            {
              label: "Reports",
              icon: "pi pi-fw pi-search-plus",
              items: [
                {
                  label: "Student Submission",
                  icon: "pi pi-fw pi-file-o",
                  to: "/assistantteachersubmission",
                },
              ],
            },
            {
              label: "Student Master",
              icon: "pi pi-fw pi-file-o",
              to: "/assstudentmaster",
            },
            {
              label: "Practice Test (JEE/NEET)",
              icon: "pi pi-fw pi-file-o",
              to: "/practicetestadmin",
            },
            {
              label: "Test Result (JEE/NEET)",
              icon: "pi pi-fw pi-file-o",
              to: "/testresultassteacher",
            },
            { label: "Mock Test", icon: "pi pi-fw pi-file-o", to: "/mocktest" },
            {
              label: "Help Desk",
              icon: "pi pi-fw pi-list",
              to: "/userqueriesmaster",
            },
          ],
        },
      ],
      menuTeacher: [
        {
          label: "Home",
          items: [
            { label: "Home", icon: "pi pi-fw pi-home", to: "/teacherhome" },
           // { label: "Dashboard", icon: "pi pi-fw pi-home", to: "/dashboard" },
            // {label: 'Crud', icon: 'pi pi-fw pi-user-edit', to: '/crud'}
          ],
        },
        {
          label: "Menu",
          icon: "pi pi-fw pi-sitemap",
          items: [
            {
              label: "Today Lecture",
              icon: "pi pi-fw pi-video",
              to: "/todaylectureteacher",
            },
            {
              label: "Previous Lecture",
              icon: "pi pi-fw pi-chevron-circle-right",
              to: "/previouslectureteacher",
            },
            {
              label: "Content Creation",
              icon: "pi pi-fw pi-pencil",
              to: "/contentcreation",
            },
            {
              label: "Assigment",
              icon: "pi pi-fw pi-exclamation-circle",
              to: "/teachersubmittedassigment",
            },
            {
              label: "Practice Test",
              icon: "pi pi-fw pi-link",
              to: "/practicetestadmin",
            },
            { label: "Test", icon: "pi pi-fw pi-sitemap", to: "/teachertest" },
            {
              label: "Ask A Doubt",
              icon: "pi pi-fw pi-question-circle",
              to: "/askdoubtteacher",
            },
            {
              label: "Reports",
              icon: "pi pi-fw pi-pencil",
              to: "/TeacherReport",
            },
            {
              label: "Help Desk",
              icon: "pi pi-fw pi-list",
              to: "/userqueriesmaster",
            },
          ],
        },
      ],
      menustudent:[{
        label: "Home",
          items: [
            { label: "Dashboard", icon: "pi pi-fw pi-home", to: "/studentdashboard" },
            // {label: 'Crud', icon: 'pi pi-fw pi-user-edit', to: '/crud'}
          ],
      },
      {
          label: "Pages",
          icon: "pi pi-fw pi-clone",
          items: [
          
            {
              label: "Library",
              icon: "pi pi-fw pi-server",
              items: [
              {
                  label: "Study Material",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/studentstudymaterial",
                },
                {
                  label: "Recorded Session",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/studentrecordedsession",
                },

              ]

            },
            {
              label: "DPP",
              icon: "pi pi-fw pi-server",
              items: [
              {
                  label: "Pending Paper",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/studentpendingpaper",
                },
                {
                  label: "Submited Paper",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/studentsubmitedpaper",
                },

              ]

            },
            {
              label: "Test Result (JEE/NEET)",
              icon: "pi pi-fw pi-file-o",
              to: "/testresultstudent",
            },
            {
              label: "Practice Test (JEE/NEET)",
              icon: "pi pi-fw pi-file-o",
              to: "/practiceteststudent",
            }

      ]
      }
          ],
      menunetwork: [
        {
          label: "Home",
          items: [
            { label: "Dashboard", icon: "pi pi-fw pi-home", to: "/dashboard" },
            // {label: 'Crud', icon: 'pi pi-fw pi-user-edit', to: '/crud'}
          ],
        },
        {
          label: "Pages",
          icon: "pi pi-fw pi-clone",
          items: [
           /* {
              label: "APP",
              items: [
                {
                  label: "Datausagelist",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/1",
                },
                {
                  label: "Networkconnectivitylist",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/2",
                },
              ],
              icon: "pi pi-fw pi-mobile",
            },*/

           /* {
              label: "STUDENT",
              items: [
                {
                  label: "List",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/3",
                },
                {
                  label: "Stdent Device Registation",
                  icon: "pi pi-fw pi-list",
                  to: "/studentdevicergistration/5",
                },
               
                {
                  label: "Stdent Attendance",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/23",
                },
                {
                  label: "Stdent Documents",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/24",
                },
                {
                  label: "Stdent Aadhar List",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/25",
                },
                {
                  label: "Stdent Redical Id List",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/26",
                },
                {
                  label: "Stdent Submitted Answers",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/29",
                },
              ],
              icon: "pi pi-fw pi-user-edit",
            },*/

           /* {
              label: "TEACHER",
              items: [
                {
                  label: "List",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/4",
                },
                {
                  label: "Teacherschedulelist",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/13",
                },
                {
                  label: "Teacherscheduledelist",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/14",
                },
                {
                  label: "Subject List",
                  icon: "pi pi-fw pi-list",
                  to: "/subjectmaster/12",
                },
                {
                  label: "Subject Topic List",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/27",
                },
              ],
              icon: "pi pi-fw pi-user-edit",
            },*/

          /*  {
              label: "TEST",
              items: [
                {
                  label: "Mock Test List",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/30",
                },
                {
                  label: "Mock Test Papers",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/31",
                },
                {
                  label: "Mock Test Results",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/32",
                },
              ],
              icon: "pi pi-fw pi-telegram",
            },*/

            {
              label: "HELP DESK",
              items: [
                // {
                //   label: "Query Types",
                //   icon: "pi pi-fw pi-list",
                //   to: "/querytypemaster",
                // },
                // {
                //   label: "Sub Query Types",
                //   icon: "pi pi-fw pi-list",
                //   to: "/subquerytypemaster",
                // },
                {
                  label: "Userquerymaster",
                  icon: "pi pi-fw pi-list",
                  to: "/userqueriesmaster",
                },
                // {
                //   label: "Query Comment list",
                //   icon: "pi pi-fw pi-list",
                //   to: "/listmaster/28",
                // },
              ],
              icon: "pi pi-fw pi-telegram",
            },

           /* {
              label: "MISC",
              items: [
                {
                  label: "News Master",
                  icon: "pi pi-fw pi-list",
                  to: "/newsmaster/6",
                },
                {
                  label: "Government Master",
                  icon: "pi pi-fw pi-list",
                  to: "/governmentmaster/7",
                },
                {
                  label: "Feedback Master",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/8",
                },
                {
                  label: "Schemes Master",
                  icon: "pi pi-fw pi-list",
                  to: "/schememaster/10",
                },
                {
                  label: "Notice Master",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/11",
                },
                {
                  label: "Video Master",
                  icon: "pi pi-fw pi-list",
                  to: "/videomaster/33",
                },
                {
                  label: "Syllabus Master",
                  icon: "pi pi-fw pi-list",
                  to: "/syllabusmaster/34",
                },
              ],
              icon: "pi pi-fw pi-table",
            },*/
           /* {
              label: "ADMIN",
              items: [
                {
                  label: "Admin Users",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/17",
                },
                {
                  label: "Application Roles",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/18",
                },
                {
                  label: "Departments",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/19",
                },
                {
                  label: "Cast List",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/20",
                },
                {
                  label: "Year List",
                  icon: "pi pi-fw pi-list",
                  to: "/yearmaster/21",
                },
                {
                  label: "Districts List",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/22",
                },
              ],
              icon: "pi pi-fw pi-table",
            },*/
          ],
        },
      ],

      menuAdmin: [
        {
          label: "Home",
          items: [
            { label: "Home", icon: "pi pi-fw pi-home", to: "/dashboard" },
            {
              label: "Dashboard",
              icon: "pi pi-fw pi-home",
              to: "/admindashboard",
            },
            // {label: 'Crud', icon: 'pi pi-fw pi-user-edit', to: '/crud'}
          ],
        },

        {
          label: "Menu",
          icon: "pi pi-fw pi-sitemap",
          items: [
            {
              label: "Administration",
              icon: "pi pi-fw pi-server",
              items: [
                {
                  label: "Manage Administrator",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/usermaster",
                },
                {
                  label: "Manage Teachers",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/teachermaster",
                },
                {
                  label: "Manage Assistant Teachers",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/assteachermaster",
                },
                {
                  label: "Manage Students",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/studentmaster",
                },
                {
                  label: "Student Device Registration",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/studentdevicergistration",
                },
                {
                  label: "Send Notifications",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/sendnotification",
                },
                {
                  label: "Notifications Configuration",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/notificationconfig",
                },
                {
                  label: "Device Master",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/devicemaster",
                },
              ],
            },

            {
              label: "Master",
              icon: "pi pi-fw pi-book",
              items: [
                {
                  label: "Courses",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/batchmaster",
                },
                {
                  label: "Subjects",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/subjectmaster/12",
                },
                {
                  label: "Bulk Students Upload",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/",
                },
                {
                  label: "Year",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/yearmaster/21",
                },
              ],
            },

            {
              label: "Student Management",
              icon: "pi pi-fw pi-id-card",
              items: [
                {
                  label: "Student Assigments",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/adminstudentassigment",
                },
                {
                  label: "Study Material",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/studymaterialadmin",
                },
                // {label: 'Practice Test( पोलीस भरती )', icon: 'pi pi-fw pi-angle-right', to: '/'},
                {
                  label: "Take a Quiz",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/",
                },
                {
                  label: "Ask a Doubt",
                  icon: "pi pi-fw pi-question-circle",
                  to: "/askdoubt",
                },
                {
                  label: "Practice Test (JEE/NEET)",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/practicetestadmin",
                },
                {
                  label: "Test Result (JEE/NEET)",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/testresultassteacher",
                },
                {
                  label: "JEE/NEET Upload Test",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/uploadtest",
                },
                {
                  label: "JEE/NEET Result Upload",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/",
                },
              ],
            },

            {
              label: "Recordings",
              icon: "pi pi-fw pi-camera",
              to: "/recording",
            },

            {
              label: "Today Lectures",
              icon: "pi pi-fw pi-video",
              to: "/todaylecture",
            },

            {
              label: "Upcoming Lectures",
              icon: "pi pi-fw pi-search-plus",
              to: "/upcominglecture",
            },

            {
              label: "Library",
              icon: "pi pi-fw pi-chevron-circle-right",
              to: "/librarymaster",
            },

            {
              label: "Reports",
              icon: "pi pi-fw pi-align-justify",
              to: "/adminreport",
            },
            {
              label: "Notice",
              icon: "pi pi-fw pi-file-o",
              to: "/noticemaster",
            },
            { label: "Mock Test", icon: "pi pi-fw pi-file-o", to: "/mocktest" },

            {
              label: "HELP DESK",
              items: [
                {
                  label: "Query Types",
                  icon: "pi pi-fw pi-list",
                  to: "/querytypemaster",
                },
                {
                  label: "Sub Query Types",
                  icon: "pi pi-fw pi-list",
                  to: "/subquerytypemaster",
                },
                {
                  label: "Userquerymaster",
                  icon: "pi pi-fw pi-list",
                  to: "/userqueriesmaster",
                },
                {
                  label: "Query Comment list",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/28",
                },
                {
                  label: "Network User Master",
                  icon: "pi pi-fw pi-list",
                  to: "/networkusermaster",
                },
              ],
              icon: "pi pi-fw pi-telegram",
            },
            {
              label: "APP",
              items: [
              
                {
                  label: "Datausagelist",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/1",
                },
                {
                  label: "Networkconnectivitylist",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/2",
                },
                {
                  label: "News Master",
                  icon: "pi pi-fw pi-list",
                  to: "/newsmaster/6",
                },
                {
                  label: "Government Master",
                  icon: "pi pi-fw pi-list",
                  to: "/governmentmaster/7",
                },
                {
                  label: "Feedback Master",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/8",
                },
                {
                  label: "Schemes Master",
                  icon: "pi pi-fw pi-list",
                  to: "/schememaster/10",
                },
                {
                  label: "Notice Master",
                  icon: "pi pi-fw pi-list",
                  to: "/listmaster/11",
                },
                {
                  label: "Video Master",
                  icon: "pi pi-fw pi-list",
                  to: "/videomaster/33",
                },
                {
                  label: "Syllabus Master",
                  icon: "pi pi-fw pi-list",
                  to: "/syllabusmaster/34",
                },
              ],
              icon: "pi pi-fw pi-mobile",
            },
            {
              label: "Network Report1",
                  icon: "pi pi-fw pi-list",
                  to: "/networkconnectivityreport1",
            },
            {
              label: "Network Report2",
                  icon: "pi pi-fw pi-list",
                  to: "/networkconnectivityreport2",
            }
          ],
        },
        /*{
					label: 'UI Components', icon: 'pi pi-fw pi-sitemap',
					items: [
						{label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/formlayout'},
						{label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/input'},
                        {label: "Float Label", icon: "pi pi-fw pi-bookmark", to: "/floatlabel"},
                        {label: "Invalid State", icon: "pi pi-fw pi-exclamation-circle", to: "/invalidstate"},
						{label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button'},
						{label: 'Table', icon: 'pi pi-fw pi-table', to: '/table'},
						{label: 'List', icon: 'pi pi-fw pi-list', to: '/list'},
						{label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree'},
						{label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel'},
						{label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay'},
                        {label: "Media", icon: "pi pi-fw pi-image", to: "/media"},
						{label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menu'},
						{label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages'},
						{label: 'File', icon: 'pi pi-fw pi-file', to: '/file'},
						{label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart'},
						{label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc'},
					]
				},
                {
                    label: 'PrimeBlocks',
                    items: [
                        {label: 'Free Blocks', icon: 'pi pi-fw pi-eye', to: '/blocks', badge: "NEW"},
                        {label: 'All Blocks', icon: 'pi pi-fw pi-globe', url: 'https://www.primefaces.org/primeblocks-vue', target: '_blank'}
                    ]
                },
                {
                    label: 'Utilities',
                    items: [
                        {label: 'PrimeIcons', icon: 'pi pi-fw pi-prime', to: '/icons'},
                        {label: 'PrimeFlex', icon: 'pi pi-fw pi-desktop', url: 'https://www.primefaces.org/primeflex/', target: '_blank'},
                    ]
                },*/
        /*{
					label: 'Pages', icon: 'pi pi-fw pi-clone',
					items: [
						{label: 'Crud', icon: 'pi pi-fw pi-user-edit', to: '/crud'},
						{label: 'Timeline', icon: 'pi pi-fw pi-calendar', to: '/timeline'},
                        {label: 'Landing', icon: 'pi pi-fw pi-globe', to: '/landing'},
                        {label: 'Login', icon: 'pi pi-fw pi-sign-in', to: '/login'},
                        {label: 'Error', icon: 'pi pi-fw pi-times-circle', to: '/error'},
                        {label: 'Not Found', icon: 'pi pi-fw pi-exclamation-circle', to: '/notfound'},
                        {label: 'Access Denied', icon: 'pi pi-fw pi-lock', to: '/access'},
						{label: 'Empty', icon: 'pi pi-fw pi-circle-off', to: '/empty'},   
					]
				},*/

        /* {
					label: 'Pages', icon: 'pi pi-fw pi-clone',
					items: [
						{label: 'APP', 
                        items: [
                                {label: 'Datausagelist', icon: 'pi pi-fw pi-list', to: '/listmaster/1'},
						        {label: 'Networkconnectivitylist', icon: 'pi pi-fw pi-list', to: '/listmaster/2'},
						
                            ],
                        icon: 'pi pi-fw pi-mobile'},
						
                        {label: 'STUDENT', 
                        items: [
                                {
                                    label: 'List', icon: 'pi pi-fw pi-list',to: '/listmaster/3'
                                },
                                {label: 'Stdent Device Registation', icon: 'pi pi-fw pi-list', to: '/studentdevicergistration/5'}, 
                                                                                                                    // /listmaster/5
                                {label: 'Stdent Attendance', icon: 'pi pi-fw pi-list', to: '/listmaster/23'},
                                {label: 'Stdent Documents', icon: 'pi pi-fw pi-list', to: '/listmaster/24'},
                                {label: 'Stdent Aadhar List', icon: 'pi pi-fw pi-list', to: '/listmaster/25'},
                                {label: 'Stdent Redical Id List', icon: 'pi pi-fw pi-list', to: '/listmaster/26'},
                                {label: 'Stdent Submitted Answers', icon: 'pi pi-fw pi-list', to: '/listmaster/29'}
                                
                            ],
                        icon: 'pi pi-fw pi-user-edit'},
						{label: 'TEACHER', 
                        items: [
                                { label: 'List', icon: 'pi pi-fw pi-list',to: '/listmaster/4'},
                                {label: 'Teacherschedulelist', icon: 'pi pi-fw pi-list', to: '/listmaster/13'},
						        {label: 'Teacherscheduledelist', icon: 'pi pi-fw pi-list', to: '/listmaster/14'},
                                {label: 'Subject List', icon: 'pi pi-fw pi-list', to: '/subjectmaster/12'},
                                {label: 'Subject Topic List', icon: 'pi pi-fw pi-list', to: '/listmaster/27'}

                            ],
                        icon: 'pi pi-fw pi-user-edit'},
						{label: 'TEST', 
                        items: [
                                {label: 'Mock Test List', icon: 'pi pi-fw pi-list', to: '/listmaster/30'},
                                {label: 'Mock Test Papers', icon: 'pi pi-fw pi-list', to: '/listmaster/31'},     
                                {label: 'Mock Test Results', icon: 'pi pi-fw pi-list', to: '/listmaster/32'}
                            ],
                        icon: 'pi pi-fw pi-telegram'},
						
                        {label: 'HELP DESK', 
                        items: [
                                {label: 'Query Types', icon: 'pi pi-fw pi-list', to: '/querytypemaster'},
                                {label: 'Sub Query Types', icon: 'pi pi-fw pi-list', to: '/subquerytypemaster'},     
                                {label: 'Userquerymaster', icon: 'pi pi-fw pi-list', to: '/userqueriesmaster'},     
                                {label: 'Query Comment list', icon: 'pi pi-fw pi-list', to: '/listmaster/28'},
                            ],
                        icon: 'pi pi-fw pi-telegram'},
						
						{label: 'MISC', 
                        items: [
                                {label: 'News Master', icon: 'pi pi-fw pi-list', to: '/newsmaster/6'},
						        {label: 'Government Master', icon: 'pi pi-fw pi-list', to: '/governmentmaster/7'},
                                {label: 'Feedback Master', icon: 'pi pi-fw pi-list', to: '/listmaster/8'},
                                {label: 'Schemes Master', icon: 'pi pi-fw pi-list', to: '/schememaster/10'},
						        {label: 'Notice Master', icon: 'pi pi-fw pi-list', to: '/listmaster/11'},
						        {label: 'Video Master', icon: 'pi pi-fw pi-list', to: '/videomaster/33'},
                                {label: 'Syllabus Master', icon: 'pi pi-fw pi-list', to: '/syllabusmaster/34'},
                            ],
                        icon: 'pi pi-fw pi-table'},
						{label: 'ADMIN', 
                        items: [
                                {label: 'Admin Users', icon: 'pi pi-fw pi-list', to: '/listmaster/17'},
						        {label: 'Application Roles', icon: 'pi pi-fw pi-list', to: '/listmaster/18'},
                                {label: 'Departments', icon: 'pi pi-fw pi-list', to: '/listmaster/19'},
                                {label: 'Cast List', icon: 'pi pi-fw pi-list', to: '/listmaster/20'},
						        {label: 'Year List', icon: 'pi pi-fw pi-list', to: '/yearmaster/21'},      
						        {label: 'Districts List', icon: 'pi pi-fw pi-list', to: '/listmaster/22'}
                            ],
                        icon: 'pi pi-fw pi-table'},
						
						
						
					]

				},*/

        /* {
                    label: 'Reports', icon: 'pi pi-fw pi-search',
                    items: [
                        {
                            label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
                            items: [
                                {
                                    label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
                                    items: [
                                        {label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark'},
                                        {label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark'},
                                        {label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark'},
                                    ]
                                },
                                {
                                    label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
                                    items: [
                                        {label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark'},
                                        {label: 'Submenu 1.2.2', icon: 'pi pi-fw pi-bookmark'}
                                    ]
                                },
                            ]
                        },
                        {
                            label: 'Submenu 2', icon: 'pi pi-fw pi-bookmark',
                            items: [
                                {
                                    label: 'Submenu 2.1', icon: 'pi pi-fw pi-bookmark',
                                    items: [
                                        {label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark'},
                                        {label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark'},
                                        {label: 'Submenu 2.1.3', icon: 'pi pi-fw pi-bookmark'},
                                    ]
                                },
                                {
                                    label: 'Submenu 2.2', icon: 'pi pi-fw pi-bookmark',
                                    items: [
                                        {label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark'},
                                        {label: 'Submenu 2.2.2', icon: 'pi pi-fw pi-bookmark'}
                                    ]
                                }
                            ]
                        }
                    ]
                },*/

        /*
                {
                    label: 'Get Started', 
                    items: [
                        {label: 'Documentation', icon: 'pi pi-fw pi-question', command: () => {window.location = "#/documentation"}},
                        {label: 'View Source', icon: 'pi pi-fw pi-search', command: () => {window.location = "https://github.com/primefaces/sakai-vue"}}
                    ]
                }*/
      ],
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
  },
  created() {
    
  },
  mounted() {
    
    // var user_name = localStorage.getItem("user_key");
    // if (user_name == "admin") {
    //   this.set_menu = this.menuAdmin;
      
    // } else if (user_name == "teacher") {
    //   this.set_menu = this.menuTeacher;
      
    // } else if (user_name == "assteacher") {
    //   this.set_menu = this.menuassistantTeacher;
      
    // } else if (user_name == "network") {
    //   this.set_menu = this.menunetwork;
     
    // }

    //----------------------------------------------------------------------------------------------------------------------------
    var page_name = this.$route.name;
    var user_name = localStorage.getItem("user_key");
    this.Menu_master = null;
    this.autoValue =null;
    this.Menu_master = new Menu();

    if (user_name == "admin") 
    { //alert(1);
      //   this.Menu_master.get_menuAdmin().then((Admin_data) => {
      //   this.autoValue = Admin_data;
      //   if (this.autoValue.indexOf(page_name.toString()) !== -1) {console.log("Yes, the value exists!");} else {this.$router.push("/access");}
      // });
      this.set_menu = this.menuAdmin;
    } 
    else if (user_name == "teacher") 
    { //alert(2);
      //   this.Menu_master.get_menuTeacher().then((Teacher_data) => {
      //   this.autoValue = Teacher_data;
      //   if (this.autoValue.indexOf(page_name.toString()) !== -1) {console.log("Yes, the value exists!");} else {this.$router.push("/access");}
      // });
      this.set_menu = this.menuTeacher;
    } 
    else if (user_name == "assteacher") 
    { //alert(3);
      // this.Menu_master.get_menuassistantTeacher().then((Ass_Teacher_data) => {
      //   this.autoValue = Ass_Teacher_data;
      //   if (this.autoValue.indexOf(page_name.toString()) !== -1) {console.log("Yes, the value exists!");} else {this.$router.push("/access");}
      // });
      this.set_menu = this.menuassistantTeacher;
    } 
    else if (user_name == "network")
    { //alert(4);
      // this.Menu_master.get_menunetwork().then((Network_data) => {
      //   this.autoValue = Network_data;
      //   if (this.autoValue.indexOf(page_name.toString()) !== -1) {console.log("Yes, the value exists!");} else {this.$router.push("/access");}
      // });
      this.set_menu = this.menunetwork;
    }
    else if(user_name == "student")
    {
      this.set_menu = this.menustudent;
    }


  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme
        ? "images/logo-white.svg"
        : "images/logo.svg";
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppConfig: AppConfig,
    AppFooter: AppFooter,
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
