export default class Menu {

    get_menuassistantTeacher() {
        return fetch('data/menu.json').then(res => res.json()).then(d => d.Ass_Teacher_data);
    }

    get_menuTeacher() {
        return fetch('data/menu.json').then(res => res.json()).then(d => d.Teacher_data);
    }

    get_menunetwork() {
        return fetch('data/menu.json').then(res => res.json()).then(d => d.Network_data);
    }

    get_menuAdmin() {
        return fetch('data/menu.json').then(res => res.json()).then(d => d.Admin_data);
    }

    get_menuStudent() {
        return fetch('data/menu.json').then(res => res.json()).then(d => d.Student_data);
    }

}